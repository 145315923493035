<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('sidebar.notifications')  + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="getList" :fields="columns">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template #cell(task_date)="data">
                                            <div>{{ data.item.task_date | dateFormat }}</div>
                                        </template>
                                        <template v-slot:cell(task_status)="data">
                                            {{ parseInt(data.item.task_status) === 1 ? $t('globalTrans.pending') : (parseInt(data.item.task_status) === 2 ? $t('globalTrans.submitted') : 'N/A') }}
                                        </template>
<!--                                        <template v-slot:cell(action)>
                                            <b-button variant="outline-success" size="sm">View</b-button>
                                        </template>-->
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskNotificationList } from '../../api/routes'
import { List, Common } from '@/mixins/helper-functions'

export default {
    mixins: [List, Common],
    data () {
        return {
            load: false,
            taskId: null,
            taskNotifications: []
        }
    },
    computed: {
        getList () {
            return this.taskNotifications.map(item => {
                item.body = item.note
                return item
            })
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.name'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_id'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_date'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' }/* ,
                { label: this.$t('globalTrans.action'), class: 'text-center' } */
            ]

            var keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'task_id' },
                { key: 'task_created_at' },
                { key: 'task_status' },
                { key: 'action' }
            ]
            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'name_bn' }
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    async created () {
        this.loadData()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            RestApi.getData(irriSchemeServiceBaseUrl, taskNotificationList, params).then(response => {
                if (response.success) {
                    this.taskNotifications = response.data.data
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
